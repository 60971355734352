import { get, post } from "./request"

//登录
export const login = p => post('/manage/user/login', p)
//获取菜单
export const getMenu = p => post('/manage/menu/list', p)
//获取系统参数
export const getSysParams = p => post('/manage/paraclass/list', p)
//系统参数改变
export const changeSysParams = p => post('/manage/paraclass/change', p)
//获取系统码值
export const getSysCode = p => post('/manage/paracode/list', p)
//系统参数改变
export const changeSysCodes = p => post('/manage/paracode/change', p)
//轮播图列表查询
export const bannerQuery = () => get('/manage/indexPic/list')
//轮播图列表查询
export const bannerChange = p => post('/manage/indexPic/change', p)
//查询码值
export const getCodeInfo = p => post('/manage/paracode/query', p)
//查询订单
export const getOrder = p => post('/manage/combineOrder/list', p)
//查询订单详情
export const getOrderDetail = p => post('/manage/combineOrder/detail', p)
//产品列表查询
export const getProductList = p => post('/manage/product/list', p)
//产品列表维护
export const productChange = p => post('/manage/product/change', p)
//App维护查询
export const AppQuery = () => get('/manage/commonPage/list')
//App维护
export const AppChange = p => post('/manage/commonPage/change', p)
//微信用户查询
export const wechatUser = p => post('/manage/wechatUser/list', p)
//微信用户设置发货权
export const wechatUserSetPosting = p => post('/manage/wechatUser/changeUserRank', p)
//修改推荐人
export const wechatUserSetRecommender = p => post('/manage/wechatUser/changeTui', p)
//修改推荐人
export const wechatUserQueryStatistic = p => post('/manage/wechatUser/mothInfo', p)
//首页数据查询
export const indexDataQuery = p => get('/manage/index/chart',p)
//系统消息查询
export const systemNewsQuery = p => post('/manage/userMessage/list', p)
//系统消息发布
export const systemNewsPublish = p => post('/manage/userMessage/save', p)
//产品信息评论查询
export const commentQuery = p => post('/manage/comment/list', p)
//产品信息评论回复
export const commentReply = p => post('/manage/comment/change', p)
//角色信息查询
export const roleQuery = p => post('/manage/role/list', p)
//角色信息维护
export const roleChange = p => post('manage/role/change', p)
//角色id查询菜单权限
export const menuRightQuery = p => post('/manage/menu/roleList', p)
//角色菜单权限
export const menuRightChange = p => post('/manage/menu/roleSave', p)
//角色权限获取
export const roleRightQuery = () => get('/manage/role/downList')
//用户设置查询
export const userSettingQuery = p => post('/manage/user/list', p)
//用户设置维护
export const userSettingChange = p => post('/manage/user/change', p)