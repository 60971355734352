<template>
    <div class="background" v-loading="loading" :style="{ width: backWidth, height: backHeight }">
        <div class="box">
            <div class="title">葡聚糖后台管理系统</div>
            <div class="form">
                <el-form ref="formRef" style="max-width: 600px" :model="dynamicValidateForm" label-width="auto"
                    class="demo-dynamic">
                    <el-form-item prop="username" label="用户名" :rules="[
        {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
        }
    ]">
                        <el-input v-model="dynamicValidateForm.username" />
                    </el-form-item>
                    <el-form-item prop="username" label="密码" :rules="[
        {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
        }
    ]">
                        <el-input type="password" v-model="dynamicValidateForm.psw" />
                    </el-form-item>
                </el-form>
                <div style="text-align: center;">
                    <el-button type="primary" @click="submitForm(formRef)">登录</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script scope setup>
import { ElNotification } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router';
import { login, getMenu } from "../../api/api.js"

const formRef = ref()
const backWidth = ref()
const backHeight = ref()
const loading = ref(false)
const router = useRouter()
const dynamicValidateForm = reactive({
    username: '',
    psw: "",
})

const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            let psw = btoa(encodeURI(dynamicValidateForm.psw))
            loading.value = true
            login({
                username: dynamicValidateForm.username,
                psw: psw,
            }).then(res => {
                if (res.code == 200) {
                    sessionStorage.setItem("userInfo", JSON.stringify(res.data))
                    ElNotification({
                        title: '提示',
                        message: "登陆成功",
                        type: "success"
                    })
                    getMenu({ roleId: res.data.roleId }).then(res => {
                        if (res.code == 200) {
                            sessionStorage.setItem("menu", JSON.stringify(res.data))
                            router.push("/")
                        } else {
                            ElNotification({
                                title: '提示',
                                message: "获取菜单失败",
                                type: "error"
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                    }).finally(() => {
                        loading.value = false
                    })
                } else {
                    ElNotification({
                        title: 'Title',
                        message: "登陆失败",
                        type: "error"
                    })
                }
            }).finally(() => {
                loading.value = false
            })
        } else {
            console.log('error submit!')
            return false
        }
    })
}

onMounted(() => {
    backWidth.value = "100%"
    backHeight.value = window.innerHeight + "px"
})
</script>

<style lang="less" scoped>
.background {
    background: linear-gradient(313deg, #62AF5A 0%, #8CD48C 100%);
    width: 100%;
    height: 100%;

    .box {
        width: 400px;
        margin: 0 auto;
        text-align: center;
        transform: translateY(50%);

        .title {
            font-size: 30px;
            color: #fff;
            padding: 20px 0;
        }

        .form {
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
        }
    }
}
</style>