<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Login from "@/views/LoginView/LoginView"
export const setVarStyle = (key, value, dom = document.documentElement) => {
  dom.style.setProperty(key, value)
}
export default {
  name: 'App',
  comments: {
    Login
  },
  data() {
    return {
      url: null
    }
  },
  mounted() {
    setVarStyle('--el-color-primary', '#62AF5A')
    setVarStyle('--el-color-primary-light-9', '#F5FBF0')
    setVarStyle('--el-color-primary-light-3', '#95d475')
    const _ = window.ResizeObserver;
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };
    window.ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };
  }
}
</script>

<style></style>
