import { createApp } from 'vue'
import App from './App.vue'
import pinia from '@/store/store'
import "@/style/index.less"
import router from '@/router/router.js'
import ElementPlus from 'element-plus' //全局引入

import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
createApp(App).use(pinia).use(router).use(ElementPlus,{
    locale: zhCn,
}).mount('#app')
