import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: '/',
        redirect: "/pujutang/index"
    },
    {
        path: '/Layout',
        name: 'Layout',
        component: () => import("@/Layout/Layout-Com.vue"),
        children: [
            {
                path: '/pujutang/index',
                name: "index",
                component: () => import("@/views/IndexView/IndexView.vue")
            },
            {
                path: '/pujutang/BasicInfo/paraclass',
                name: "basicParams",
                component: () => import("@/views/BasicParams/BasicParams.vue")
            },
            {
                path: '/pujutang/BasicInfo/role',
                name: "basicRole",
                component: () => import("@/views/RoleSetting/RoleSetting.vue")
            },
            {
                path: '/pujutang/BasicInfo/indexPic',
                name: "BasicInfoIndexPic",
                component: () => import("@/views/BannerView/BannerView.vue")
            },
            {
                path: '/pujutang/ProductManage/product',
                name: "ProductManage",
                component: () => import("@/views/ProductManage/ProductManage.vue")
            },
            {
                path: '/pujutang/CombineOrder/combineOrder',
                name: "OrderManage",
                component: () => import("@/views/OrderManage/OrderManage.vue")
            },
            {
                path: '/pujutang/BasicInfo/commonPage',
                name: "AppManage",
                component: () => import("@/views/AppManage/AppManage.vue")
            },
            {
                path: '/pujutang/UserManage/wechatUser',
                name: "wechatUser",
                component: () => import("@/views/WechatUser/WechatUser.vue")
            },
            {
                path: '/pujutang/UserManage/user',
                name: "userSetting",
                component: () => import("@/views/UserSetting/UserSetting.vue")
            },
            {
                path: '/pujutang/MessageManage/message',
                name: "systemNews",
                component: () => import("@/views/SystemNews/SystemNews.vue")
            },
            {
                path: '/pujutang/MessageManage/comment',
                name: "commentReply",
                component: () => import("@/views/CommentReply/CommentReply.vue")
            },

        ]
    },
    {
        path: '/LoginView',
        name: 'LoginView',
        component: () => import('@/views/LoginView/LoginView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// 设置全局前置守卫
router.beforeEach((to, from, next) => {
    // 在这里编写守卫逻辑
    if (to.path == "/LoginView" && sessionStorage.getItem('userInfo')) {
        next("/pujutang/BasicInfo/paraclass")
    } else {
        next()
    }
    // 必须调用next()来resolve这个钩子
});
export default router
