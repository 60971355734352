import router from "@/router/router"
import axios from "axios";
import { ElMessage } from 'element-plus'

axios.defaults.withCredentials = true//设置发送请求时运行携带cookie信息
axios.defaults.crossDomain = true;
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'https://www.pjtysm.com';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'https://www.pjtysm.com';
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://www.pjtysm.com';
}
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8;';


/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.data?.code == '401') {
            ElMessage.error(response.data.msg)
            sessionStorage.clear()
            router.push('/LoginView')
        }
        return response
    },
    (error) => {
        console.log("请求出错：", error);
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params}).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                //关闭进度条
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
}